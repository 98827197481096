import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function CapeReinga90MileBeach(props) {
  return (
    <>
      <SEO title="Cape Reinga Tour | 90 Mile Beach Tour - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/cape-reinga-sand-boarding.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/cape-reinga-scenery.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/cape-reinga-beach.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/cape-reinga-bus.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            {/* bookingsource: AWWEB, cat: CRK, tour: CRK */}
            <BookingForm tourCode="CRK" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Cape Reinga &amp; 90 Mile Beach</h1>
                <h3>
                  Journey to the top of New Zealand on a jam-packed day tour.
                </h3>
                <div class="highlights">
                  <ul>
                    <li>Sandboarding at Te Paki Stream</li>
                    <li>Puketi Kauri Forest and Ancient Kauri Kingdom</li>
                    <li>Photo opportunities galore</li>
                    <li>Free hotel pick ups and drop-offs</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <h2 class="tour">The Best Bits</h2>
                    <ul>
                      <li>Sandboarding at Te Paki stream</li>
                      <li>Cape Reinga</li>
                      <li>Drive along 90 Mile Beach</li>
                      <li>Puketi Kauri Forest and Ancient Kauri Kingdom</li>
                      <li>
                        Real Kiwi fish 'n' chips at Mangonui (own expense)
                      </li>
                      <li>Photo opportunities galore</li>
                    </ul>
                    <h2 class="tour">About This Tour</h2>
                    <p>
                      A visit to New Zealand is not complete without a journey
                      to its northernmost point – <strong>Cape Reinga</strong>.
                      Discover the wild beauty of this vast and untouched
                      region, rich in Māori culture and tradition.
                    </p>
                    <p>
                      As you set off from <strong>Paihia</strong> in the{" "}
                      <strong>Bay of Islands</strong>, your driver guide will
                      keep you entertained with tales of the region's history,
                      from the arrival of the Māori to present day. Our drivers
                      are an amazing bunch, passionate about what they do and
                      about the area they call home. We reckon they'll make your
                      day even more special!
                    </p>
                    <p>
                      Stop at the seaside community of Taipa in{" "}
                      <strong>Doubtless Bay</strong> for a morning snack, before
                      continuing to the very tip of Aotearoa – Cape Reinga.
                      According to Māori legend, the headland (also known as Te
                      Rerenga Wairua) is the departing place for spirits making
                      their final journey to the homeland, Hawaiki. Soak up the
                      atmosphere of this special and powerfully beautiful place,
                      as the Pacific Ocean and Tasman Sea collide far below you.
                      See the famous <strong>Cape Reinga Lighthouse</strong> and
                      snap a picture of the signpost showing the distance from
                      here to the rest of the world.
                    </p>
                    <p>
                      Depending on the tides, your journey to or from Cape
                      Reinga will include a drive along the sand and surf on
                      legendary Ninety Mile Beach – a registered highway. Smell
                      the salt air and get some toe-digging tips from your
                      driver as you hunt for pipi (fresh shell fish).
                    </p>
                    <p>
                      During your tour you'll also visit the giant sand dunes at{" "}
                      <strong>Te Paki Stream</strong> at the top of 90 Mile
                      Beach. After a quick lesson on the basics of sandsurfing,
                      grab a board and make the climb to race down the dunes – a
                      real adrenaline rush!
                    </p>
                    <p>
                      For something different, stop en route and take a short
                      walk through the 15,000 hectare{" "}
                      <strong>Puketi Kauri Forest</strong>. Take a deep breath
                      of fresh NZ air while you hug a 2,000 year-old tree and
                      gaze up at the rainforest canopy high overhead .
                    </p>
                    <p>
                      On the way home, we'll stop at <strong>Mangonui</strong>{" "}
                      so you can try the best fish and chips in the world,
                      before heading back to the Bay.
                    </p>
                    <p>
                      Please note child fares are not available on this
                      tour.&nbsp;
                      <br />
                      <br />
                    </p>

                    <div class="clear"></div>

                    <div id="Summary" class="row">
                      <h2 class="tour">Refreshments &amp; Fish n' Chips</h2>
                      <p>
                        We'll stop along the way so you can buy morning tea,
                        lunch and afternoon tea - plus authentic Kiwi fish n'
                        chips in Mangonui!
                      </p>
                      <p>
                        Pipi picking on 90 Mile Beach is dependent on
                        conditions.
                      </p>
                    </div>

                    <div id="TravellerReviews0">
                      <div id="TravellerReviews">
                        <div id="TA_selfserveprop658" class="TA_selfserveprop">
                          <ul id="xnzgQowZ" class="TA_links 1RcVU2l">
                            <li id="VbAcVntwtjO" class="n0AvzlW">
                              <a href="https://www.tripadvisor.co.nz/Attraction_Review-g255110-d1546340-Reviews-AwesomeNZ-Paihia_Bay_of_Islands_Northland_Region_North_Island.html">
                                <img
                                  title=""
                                  src="https://www.tripadvisor.co.nz/img/cdsi/img2/branding/150_logo-11900-2.png"
                                  alt="TripAdvisor"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>
                {/* ... */}
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/bay-of-islands-tours/awesome-combo">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/capereinga_dolphins.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Awesome Combo</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$228.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/bay-of-islands-tours/awesome-combo">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default CapeReinga90MileBeach
